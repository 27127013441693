<template>
  <div class="content">
    <v-runtime-template v-if="content !== null" :template="content" />
  </div>
</template>

<script>
import { marked } from 'marked';
import VRuntimeTemplate from 'vue3-runtime-template';

/**
 * A Marked extension to enable the matching and rendering of a code reference.
 * A code reference is a URL to a code file that should be displayed.
 */
const codeRef = {
  name: 'codeRef',
  level: 'block',
  start(src) { return src.match(/!{/)?.index; },
  tokenizer(src) {
    const rule = /^!{([^}\n]+)}(?:\n|$)/; // e.g. !{https://example.com/code.py}
    const match = rule.exec(src);
    if (match) {
      const token = {
        type: 'codeRef',
        raw: match[0],
        url: match[1].trim()
      };
      return token;
    }
  },
  renderer(token) {
    return `<RenderedElementCode src="${token.url}" :isAdminPreview="isAdminPreview" />`;
  },
  childTokens: ['code'],
};

import { cleanUrl } from 'marked/src/helpers'

const imageRenderer = {
  image(href, title, text) {
    href = cleanUrl(false, null, href);
    if (href === null) return text;

    let out = `<RenderedElementImage href="${href}"`;
    if (title) out += ` title="${title}"`;
    if (text) out += ` text="${text}"`;
    out += ' :isAdminPreview="isAdminPreview" />';
    return out;
  }
};

marked.use({ extensions: [codeRef] });
marked.use({ renderer: imageRenderer });

export default {
  name: 'MarkdownViewer',
  components: {
    VRuntimeTemplate
  },
  props: {
    markdown: String,
    isAdminPreview: Boolean
  },
  data() {
    return {
      content: null
    }
  },
  watch: {
    markdown: {
      immediate: true,
      handler(newVal) {
        if (newVal === null) this.content = null;
        else this.content = marked.parse(newVal);
      }
    }
  },
}
</script>

<style lang="scss">
.content a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
</style>
